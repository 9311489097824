import { Box, Button, Grid, GridItem, Heading, HStack, Input, Text, VStack } from '@chakra-ui/react';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Config from '../../../../../../lib/Config';
import { TicketContext } from '../../../../../../lib/Providers/TicketDetails';

const CartAdded = (props) => {
    const { cartdata } = props;
    const { detailedticket, setFetch } = useContext(TicketContext);

    const [quantities, setQuantities] = useState({});

    
    useEffect(() => {
        if (cartdata && Array.isArray(cartdata)) {
            const initialQuantities = cartdata.reduce((acc, item) => {
                acc[item.mastermenuItemId] = item.quantity || 0;
                return acc;
            }, {});
            setQuantities(initialQuantities);
        }
    }, [cartdata]);

    
    const handleSaveQuantity = async (item, newQuantity) => {
        const medi = {
            mastermenuItemId: item.mastermenuItemId,
            medicineName: item.medicineName,
            quantity: newQuantity,
            unit: "unit",
            mrp: item.mrp,
            price: item.price,
            hdpOrderId: detailedticket.id,
            promcode_applicable: item?.promcode_applicable,
        };

        try {
            await axios.post(Config.Add_Or_Update_Cart, medi);
            setFetch((prev) => prev + 1);
        } catch (err) {
            console.log(err);
        }
    };

    const handleDecrease = async (item) => {
        const currentQuantity = quantities[item.mastermenuItemId] || item.quantity;
        const newQuantity = currentQuantity - 1;
        
            setQuantities((prev) => ({
                ...prev,
                [item.mastermenuItemId]: newQuantity,
            }));
            await handleSaveQuantity(item, newQuantity);
    };

    const handleIncrease = async (item) => {        
        const currentQuantity = quantities[item.mastermenuItemId] || item.quantity;
        const newQuantity = currentQuantity + 1;
        setQuantities((prev) => ({
            ...prev,
            [item.mastermenuItemId]: newQuantity,
        }));
        await handleSaveQuantity(item, newQuantity);
    };

    const handleInputChange = (e, item) => {
        const value = e.target.value;
        const numericValue = value === "" ? "" : parseInt(value, 10);
        if (value === "" || (!isNaN(numericValue) && numericValue >= 1)) {
            setQuantities((prev) => ({
                ...prev,
                [item.mastermenuItemId]: numericValue,
            }));
        }
    };

    const handleInputBlur = async (item) => {
        const inputValue = quantities[item.mastermenuItemId];

        const newQuantity = inputValue !== "" ? parseInt(inputValue, 10) : 0;
        if (!isNaN(newQuantity)) {
            setQuantities((prev) => ({
                ...prev,
                [item.mastermenuItemId]: newQuantity,
            }));
            await handleSaveQuantity(item, newQuantity);
        } else {
            setQuantities((prev) => ({
                ...prev,
                [item.mastermenuItemId]: cartdata.find(i => i.mastermenuItemId === item.mastermenuItemId).quantity, // Reset to original
            }));
        }
    };

    return (
        <>
            {cartdata && cartdata.map((item, index) => (
                <Grid key={`${item.mastermenuItemId}-${index}`} borderBottom="solid 2px #f7f7f7" templateColumns='repeat(12, 1fr)' gap={0}>
                    <GridItem colSpan={8} py={4} w='100%' h='100%'>
                        <VStack align="flex-start" spacing={2}>
                            <Heading textTransform={'capitalize'} fontWeight={600} fontSize={14} color="#474747">{item.medicineName}</Heading>
                            <Heading color="#A23DFF" textAlign="start" as="h6" fontWeight={600} fontSize={14}>{(100 - (item.price * 100) / item.mrp).toFixed(0)}% off</Heading>
                            <HStack justifyContent="space-between" width="100%">
                                <Heading color="#474747" textAlign="start" as="h6" fontWeight={600} fontSize={14}>
                                    <Text textDecoration="line-through" as="span">₹{((item.mrp) * item.quantity).toFixed(2)}</Text>
                                    <Text ml={2} color="#00AB83" as="span">₹{((item.price) * item.quantity).toFixed(2)}</Text></Heading>
                            </HStack>
                        </VStack>
                    </GridItem>
                    <GridItem colSpan={4} py={4} w='100%' h='100%'>
                        <HStack border="solid 1px #000" rounded="50px">
                            <Button
                                bg="none"
                                width="auto"
                                minW="auto"
                                px="4"
                                height="auto"
                                _hover={{ bg: 'none' }}
                                onClick={() => { handleDecrease(item); }}
                            >-</Button>
                            <Input
                                color="#00AB83"
                                textAlign="center"
                                value={quantities[item.mastermenuItemId] || 0}
                                ml="0!important"
                                px={1}
                                _focus={{ border: 'none', outlineColor: "none", boxShadow: 'none' }}
                                border="none"
                                onChange={(e) => handleInputChange(e, item)}
                                onBlur={() => handleInputBlur(item)}
                            />
                            <Button
                                bg="none"
                                width="auto"
                                minW="auto"
                                px="4"
                                height="auto"
                                ml="0!important"
                                _hover={{ bg: 'none' }}
                                onClick={() => { handleIncrease(item); }}
                            >+</Button>
                        </HStack>
                    </GridItem>
                </Grid>
            ))}
        </>
    );
};

export default CartAdded;
